<template>
    <div class="carousel">
        <div class="tabs" style="display: flex;flex-wrap:nowrap; overflow-x: auto; margin-bottom: 50px;">
            <div v-for="tab in tabs" :key="tab.title" class="tab">
                <span @click="changetab(tab.link)">{{ tab.title }}</span>
            </div>
        </div>
        <iframe :src="currentTab" frameborder="0" width="100%" height="400px"></iframe>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tabs: [
                {title: 'Accessories', link: 'https://app.splendapp.com/carousel/7596d195-bdd1-4241-bac8-a515c8475871'},
                {title: 'Drawers', link: 'https://app.splendapp.com/carousel/1f0ffea6-2b19-42d0-aca0-bbe072930386'},
                {title: 'Desks', link: 'https://app.splendapp.com/carousel/57d3689e-3c6e-4c55-8acd-d759155569ee'},
                {title: 'Seating', link: 'https://app.splendapp.com/carousel/83715193-935c-4cb0-896e-921c4bb9938d'},
                {title: 'Accessories', link: 'https://app.splendapp.com/carousel/7596d195-bdd1-4241-bac8-a515c8475871'},
                {title: 'Drawers', link: 'https://app.splendapp.com/carousel/1f0ffea6-2b19-42d0-aca0-bbe072930386'},
                {title: 'Desks', link: 'https://app.splendapp.com/carousel/57d3689e-3c6e-4c55-8acd-d759155569ee'},
                {title: 'Seating', link: 'https://app.splendapp.com/carousel/83715193-935c-4cb0-896e-921c4bb9938d'}
            ],
            currentTab: ''
        }
    },
    methods: {
        changetab(link) {
            this.currentTab = link;
        }
    },
    mounted() {
        this.currentTab = this.tabs[0].link;
    }
}
</script>

<style>
.tab{
    width: 100px;
    padding: 10px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #bbbbbb6e;
    margin-right: 20px;
}
.tab.active{
    border: 1px solid #000;
}
.tabs::-webkit-scrollbar {
    display: none;
}

</style>