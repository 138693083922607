<template>
    <div class="notification">
        <h1>Notifications</h1>
        <div class="notification-list" v-if="list">
            <div class="notification-item" v-for="item in list" :key="item.id" >
                <div class="notification-content">
                    <img :src="item.imageUrl" alt="">
                    <div class="text">
                        <div class="notification-title">
                            {{ item.title }}
                        </div>
                        <div class="notification-body">
                            {{ item.body }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import data from "../../../notification.json"
export default {
    data (){
        return{
            list: null,
        }
    },
    mounted(){
        this.list = data.pagination.items.filter((item) => item.imageUrl)
        console.log(this.list)
    }
}
</script>

<style scoped>
.notification > h1{
    font-size: 20px;
    margin-bottom: 20px;
    color: #2d2626;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
}
.notification-list{
    background-color: #f5f5f5;
    padding: 10px 0;
}
.notification-item{
    margin-bottom: 15px;
    background-color: white;
    padding: 20px;
}
.notification-content{
    background-color: #f5f5f5;
    border-radius: 13px;
    height: 150px;
    display: flex;
    align-items: center;
    padding: 5px;
}
.notification-content img{
    width: 30%;
    height: auto;
    margin-right: 10px;
}
.notification-item .text{
    margin-bottom: 10px;
    color: #1a1a1a;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.notification-title{
    font-size: 15px;
}
.notification-body{
    font-size: 18px;
    font-weight: 500;
}
</style>