<template>
    <div  class="categories" :style="{
        'direction': language == 'en' ? 'ltr' : 'rtl'
    }">
        <swiper
            @swiper="setThumbsSwiper"
            :spaceBetween="0"
            freeMode="true"
            slidesPerView="auto"
            :style="{'background-color' : styleData?.sideMenuCompactSideBarColor, }"
            :modules="modules"
            class="mySwiper main-categories" style="padding: 10px;">
                <swiper-slide
                    v-for="(mainCategory, index) in data" :key="mainCategory.id" style="padding: 5px 15px;border-radius: 40px;" :style="{
                        'background-color' : activeMainCategory === index ? styleData?.sideMenuCompactSideActiveItemColor : '',
                    }">
                    <div class="main-category-image" v-if="mainCategory.translations[language].imageUrl" 
                    :style="{
                        'margin-right' : mainCategory.translations[language].name && language == 'en' ? '5px': '',
                        'margin-left' : mainCategory.translations[language].name && language == 'ar' ? '5px': '',
                        }"
                    >
                        <img :src="mainCategory.translations[language].imageUrl" style="margin-bottom: 0;">
                    </div>
                    <div class="main-category-name" v-if="mainCategory.translations[language].name" 
                    :style="{
                        'font-family' : styleData?.fonts.find(font => font.locale == language).fontFamily,
                        'color' : activeMainCategory === index ? styleData?.sideMenuCompactActiveTextColor : styleData?.sideMenuCompactTextColor,
                        'font-size' : styleData?.fonts.find(font => font.locale == language).fontSize +'px',
                        'font-weight': activeMainCategory === index ? '700':  '400',
                    }"  >
                        {{ mainCategory.translations[language].name }}
                    </div>
                </swiper-slide>
        </swiper>
                <swiper
                :spaceBetween="10"
                :thumbs="{ swiper: thumbsSwiper }"
                :modules="modules"
                @slideChange="onSlideChange"
                class="mySwiper2">
                <swiper-slide
                v-for="(mainCategory) in data" :key="mainCategory.id">
                    <div class="sub-categories">
                        <div class="side-bar" style="height: calc(100vh - 60px)"  :style="{'background-color' : styleData?.sideMenuCompactSideBarColor, }">
                            <div class="sub-category"
                            @click="scrollToContent(subCategory.id, index)"
                            :ref="'subCategory-' + mainCategory.id + '-' + subCategory.id"
                                v-for="(subCategory, index) in mainCategory.children" :key="subCategory.id"
                                :style="{
                                    'background-color' : index === activeSubCategory ? styleData?.tertiaryColor : '',
                                    'border-left': index === activeSubCategory && language == 'en' ? '3px solid ' + styleData?.sideMenuCompactSideActiveItemColor : '',
                                    'border-right': index === activeSubCategory && language == 'ar' ? '3px solid ' + styleData?.sideMenuCompactSideActiveItemColor : ''
                                }" :class="{ 'active-category': index === activeSubCategory }" >
                                <div class="sub-category-image" v-if="subCategory.translations[language].imageUrl">
                                    <img :src="subCategory.translations[language].imageUrl" >
                                </div>
                                <div class="sub-category-name" :style="{
                                    'font-family' : styleData?.fonts.find(font => font.locale == language).fontFamily,
                                    'color' : index === activeSubCategory ? styleData?.sideMenuCompactTextColor : styleData?.sideMenuCompactTextColor,
                                    'font-size' : (styleData?.fonts.find(font => font.locale == language).fontSize - 2) +'px',
                                    'font-weight': activeSubCategory === index ? '700':  '400',
                                }"  v-if="subCategory.translations[language].name">
                                    {{ subCategory.translations[language].name }}
                                </div>
                            </div>
                        </div>
                        <div class="third-childrens" :id="'subCategories' + mainCategory.id" @scroll="handleScroll" :style="{'background-color' : styleData?.sideMenuCompactBgColor}" style="flex: 0 0 75%;overflow-y: auto;height: calc(100vh - 60px);padding-top: 10px">
                            <div class="content" :ref="'content-' + subCategory.id" v-for="subCategory in mainCategory.children" :key="subCategory.id" style="display: flex; flex-wrap: wrap;">
                                <h3 style="margin: 0 10px 15px;width: 100%;" :style="{'font-size' : styleData?.fonts.find(font => font.locale == language).fontSize +'px',}">{{ subCategory.translations[language].name }}</h3>
                                <a target="_blank" :href="thirdChild.translations[language].url" class="third-children" style="display: flex;flex-direction: column;align-items: center;overflow: hidden;margin:0 10px 10px;text-align: center;" :style="{
                                'width': thirdChild.translations[language].imageUrl ? '': '80px',
                                'height': thirdChild.translations[language].imageUrl ? '': '65px',
                                'justify-content': thirdChild.translations[language].imageUrl ? '' : 'center',
                                'align-items': thirdChild.translations[language].imageUrl ? '' : 'center',
                                'background-color': thirdChild.translations[language].imageUrl ? '' : '#f8f8f8',
                                'border-radius': thirdChild.translations[language].imageUrl ? '' : '5px',
                                'padding': thirdChild.translations[language].imageUrl ? '' : '5px',
                                'flex':  `0 0 ${thirdChild.translations[language].imageStyle == '1' ? 'calc(100% - 20px)' : (thirdChild.translations[language].imageStyle == '2' ? 'calc(50% - 20px)' : (thirdChild.translations[language].imageStyle == '3' ? 'calc(33% - 20px)' : 'calc(100% - 20px)'))}`
                                }" v-for="thirdChild in subCategory.children" :key="thirdChild.id">
                                    <div class="third-child-image">
                                        <img :src="thirdChild.translations[language].imageUrl" v-if="thirdChild.translations[language].imageUrl"
                                        :style="{
                                                    'margin-bottom' : thirdChild.translations[language].name? '': 0,
                                                }"
                                        >
                                    </div>
                                    <div class="third-child-name" v-if="thirdChild.translations[language].name" :style="{
                                        'color' : styleData?.sideMenuCompactTextColor,
                                        'font-family' : styleData?.fonts.find(font => font.locale == language).fontFamily,
                                        'font-size' :  thirdChild.translations[language].imageStyle == '2' ? (styleData?.fonts.find(font => font.locale == language).fontSize - 2 )+'px' : (styleData?.fonts.find(font => font.locale == language).fontSize - 4 )+'px', 
                                        }">
                                            {{ thirdChild.translations[language].name }}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                </swiper>
    </div>
</template>
    
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    props:{
        data : {
            type: Array,
            default: null,
        },
        styleData: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            language: 'en',
            firstChildWithChildrenIndex: 0,
            activeMainCategory: 0,
            activeSubCategory:0,
            thumbsSwiper: null,
        }
    },
    methods: {
        setThumbsSwiper(swiper) {
            this.thumbsSwiper = swiper;
        },
        scrollToContent(subCategoryId, index) {
            this.activeSubCategory = index
            const contentRef = this.$refs['content-' + subCategoryId][0];
            if (contentRef) {
                contentRef.scrollIntoView({ behavior: 'auto' });
            }
        },
        handleScroll() {
            this.data[this.activeMainCategory].children.forEach((subCategory, index) => {
                const contentRef = this.$refs['content-' + subCategory.id][0];
                if (contentRef) {
                    const rect = contentRef.getBoundingClientRect();
                    if (rect.top >= 0 && rect.top <= window.innerHeight / 2) {
                        this.activeSubCategory = index;
                    }
                }
            });
        },
        onSlideChange(swiper){
            this.activeMainCategory = swiper.activeIndex
            this.activeSubCategory = 0
                const contentContainer = this.$el.querySelector(`#subCategories${this.data[this.activeMainCategory].id}`);
                if (contentContainer) {
                    contentContainer.scrollTo({ top: 0, behavior: 'auto' });
                    this.handleScroll()
                }
        },
    },
    
    setup() {
        return {
            modules: [FreeMode, Navigation, Thumbs],
        };
    },
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&family=Almarai:wght@300;400;700;800&family=Cairo:wght@200..1000&family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Tajawal:wght@200;300;400;500;700;800;900&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
.mySwiper {
    overflow: hidden;
}
.mySwiper .swiper-slide{
    box-sizing: border-box;
    text-align: center;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.side-bar{
    background-color: #f7f8fa;
    overflow-y: auto;
    overflow-x: hidden ;
    display: flex;
    flex-direction: column ;
    flex: 0 0 25%;
    height: 90vh;
}
.side-bar::-webkit-scrollbar{
    display: none;
}
.content::-webkit-scrollbar{
    display: none;
}
.main-category{
    padding: 10px 10px;
    border-bottom: 1px solid #f1f1f1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.accordion-button{
    background-color: white ;
    box-shadow: none !important;
    font-size: 14px !important;
    font-weight: 400;
    padding: 0px 0px 10px !important;
    opacity: 0.800;
}
.accordion-button:not(.collapsed){
    opacity: 1;
    font-weight: 700;
}
.accordion-item{
    border: none !important;
    margin-bottom: 10px;
    text-align: center;
}
.accordion-button::after{
    width: 15px !important;
    height: 15px !important;
    background-size: contain !important;
}
img{
    width: 100%;
    height: auto;
    margin-bottom: 7px;
}
.third-child{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.third-child-name{
    font-size: 12px;
}
.accordion-body{
    padding: 0 !important;
}
.active-category{
    background-color: #fff;
    position: relative;
}
/*.active-category::after{
    position: absolute;
    top: 50%;
    left: 0;
    width: 3px;
    height: 100%;
    content: "";
    background: #ff761b;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}*/
.third-child {
    margin: 10px 0;
}
.accordion-item > div >.third-child {
    margin: 0px 0;
}
.main-category-name{
    font-size: 12px;
    font-weight: 400;
}
.active-category .main-category-name{
    font-size: 12px;
    font-weight: 700;
}
.third-child-name{
    font-size: 10px;
    font-weight: 400;
}
.accordion-button::after{
    transform: rotate(-90deg);
}
.categories[style="direction: rtl;"] .accordion-button::after{
    transform: rotate(90deg);
}
.accordion-button:not(.collapsed)::after{
    transform: unset !important;
}
.accordion-button:nth-child(1){
    margin-top: 10px;
}
.accordion-item:first-of-type{
    border-radius: 0 !important;
    margin-top: 10px !important;
}
.accordion-item{
    margin-bottom: 10px !important;
}
.categories[style="direction: rtl;"] .accordion-button::after{
    margin-right: auto;
    margin-left: unset !important;
}
.main-category-image img{
    width: 19px;
    height: 18px;
} 
.sub-categories{
    display: flex;
}
.sub-category{
    padding: 10px 10px;
    border-bottom: 1px solid #f1f1f1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
a{
    text-decoration: none;
}
a:active{
    opacity: .5 !important;
}

</style>
    