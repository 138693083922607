<template>
    <div  class="categories" :style="{
        'direction': language == 'en' ? 'ltr' : 'rtl'
    }">
        <div class="side-bar">
            <div class="main-category" :style="{
                'background-color' : children === category.children ? styleData?.tertiaryColor : '',
                'border-left': children === category.children && language == 'en' ? '3px solid ' + styleData?.sideMenuCompactSideActiveItemColor : '',
                'border-right': children === category.children && language == 'ar' ? '3px solid ' + styleData?.sideMenuCompactSideActiveItemColor : ''
            }" :class="{ 'active-category': children === category.children }" @click="changeCategory(category)" v-for="category in data" :key="category.id">
                <div class="main-category-image" v-if="category.translations[language].imageUrl">
                    <img :src="category.translations[language].imageUrl" >
                </div>
                <div class="main-category-name" :style="{
                    'font-family' : styleData?.fonts.find(font => font.locale == language).fontFamily,
                    'font-size' : (styleData?.fonts.find(font => font.locale == language).fontSize - 2 )+'px',
                    'color' : children === category.children ? styleData?.sideMenuCompactTextColor : styleData?.sideMenuCompactTextColor,
                }"  v-if="category.translations[language].name">{{ category.translations[language].name }}</div>
            </div>
        </div>
        <div class="content" v-if="children">
            <div class="accordion" :style="{
                'background-color' : styleData?.sideMenuCompactBgColor
            }" id="accordionExample" style="display: flex;justify-content: space-between;flex-wrap: wrap;">
                <div class="accordion-item"  style="margin: 0 10px" v-for="(child, index) in children" :key="child.id" :style="{
                    'background-color' : styleData?.sideMenuCompactBgColor,
                    'display' : !child.translations[language].imageUrl && !child.children.length > 0 ? 'flex' : '',
                    'justify-content': !child.translations[language].imageUrl && !child.children.length > 0 ? 'center' : '',
                    'align-items': !child.translations[language].imageUrl && !child.children.length > 0 ? 'center' : '',
                    'background-color': !child.translations[language].imageUrl && !child.children.length > 0 ? '#f8f8f8' : '',
                    'border-radius': !child.translations[language].imageUrl && !child.children.length > 0 ? '5px' : '',
                    'padding': !child.translations[language].imageUrl && !child.children.length > 0 ? '5px' : '',
                    'height': !child.translations[language].imageUrl && !child.children.length > 0 ? '65px' : '',
                    'flex':  `0 0 ${child.translations[language].imageStyle == '1' ? 'calc(100% - 20px)' : (child.translations[language].imageStyle == '2' ? 'calc(50% - 20px)' : (child.translations[language].imageStyle == '3' ? 'calc(33% - 20px)' : 'calc(100% - 20px)'))}`,}">
                    <div v-if="child.children.length > 0">
                        <h2 class="accordion-header" >
                            <button class="accordion-button" :style="{
                                'background-color' : styleData?.sideMenuCompactBgColor,
                                'color' : styleData?.sideMenuCompactTextColor,
                                'font-family' : styleData?.fonts.find(font => font.locale == language).fontFamily ,
                                'font-size' : styleData?.fonts.find(font => font.locale == language).fontSize +'px',
                }" :class="{ 'collapsed': index != firstChildWithChildrenIndex}" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse'+index" aria-expanded="true" :aria-controls="'collapse'+index">
                                {{ child.translations[language].name }}
                            </button>
                        </h2>
                        <div :id="'collapse'+index" class="accordion-collapse collapse" :class="{ 'show': index === firstChildWithChildrenIndex }" >
                            <div class="accordion-body" style="display: flex;flex-wrap: wrap; column-gap: 10px;">
                                <a target="_blank" :href="thirdChild.translations[language].url" class="third-child" v-for="thirdChild in child.children" :key="thirdChild.id" style="overflow: hidden;" :style="{
                                    'width': thirdChild.translations[language].imageUrl ? '': '80px',
                                    'height': thirdChild.translations[language].imageUrl ? '': '65px',
                                    'justify-content': thirdChild.translations[language].imageUrl ? '' : 'center',
                                    'align-items': thirdChild.translations[language].imageUrl ? '' : 'center',
                                    'background-color': thirdChild.translations[language].imageUrl ? '' : '#f8f8f8',
                                    'border-radius': thirdChild.translations[language].imageUrl ? '' : '5px',
                                    'padding': thirdChild.translations[language].imageUrl ? '' : '5px',
                                    'flex':  `0 0 ${thirdChild.translations[language].imageStyle == '1' ? '100%' : (thirdChild.translations[language].imageStyle == '2' ? 'calc(50% - 5px)' : (thirdChild.translations[language].imageStyle == '3' ? 'calc(33% - 6.66px)' : 'calc(100% - 20px)'))}`,}">
                                    <img :src="thirdChild.translations[language].imageUrl" v-if="thirdChild.translations[language].imageUrl" 
                                    :style="{
                                        'margin-bottom' : thirdChild.translations[language].name? '': 0,
                                    }"
                                    >
                                    <div class="third-child-name" :style="{
                                        'color' : styleData?.sideMenuCompactTextColor,
                                        'font-family' : styleData?.fonts.find(font => font.locale == language).fontFamily,
                                        'font-size' :  thirdChild.translations[language].imageStyle == '2' ? (styleData?.fonts.find(font => font.locale == language).fontSize - 2 )+'px' : (styleData?.fonts.find(font => font.locale == language).fontSize - 4 )+'px',
                                    }">
                                        {{ thirdChild.translations[language].name }}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div v-else >
                        <a :href="child.translations[language].url" target="_blank" class="third-child">
                            <img :src="child.translations[language].imageUrl" v-if="child.translations[language].imageUrl"
                            :style="{
                                        'margin-bottom' : child.translations[language].name? '': 0,
                                    }"
                            >
                            <div class="third-child-name" :style="{
                                'color' : styleData?.sideMenuCompactTextColor,
                                'font-family' : styleData?.fonts.find(font => font.locale == language).fontFamily,
                                'font-size' :  child.translations[language].imageStyle == '2' ? (styleData?.fonts.find(font => font.locale == language).fontSize - 2 )+'px' : (styleData?.fonts.find(font => font.locale == language).fontSize - 4 )+'px',
                            }">
                                {{ child.translations[language].name }}
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            language: 'en',
            children: null,
            firstChildWithChildrenIndex: 0,
        }
    },
    props:{
        data : {
            type: Array,
            default: null,
        },
        styleData: {
            type: Object,
            default: null,
        }
    },
    mounted(){
        if(this.data[0].children.length > 0){
            this.children=this.data[0].children
        }
        this.firstChildWithChildrenIndex = this.children.findIndex(child => child.children.length > 0);
    },
    methods: {
        changeCategory(cat){
            this.children = cat.children;
            this.firstChildWithChildrenIndex = this.children.findIndex(child => child.children.length > 0);
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&family=Almarai:wght@300;400;700;800&family=Cairo:wght@200..1000&family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Tajawal:wght@200;300;400;500;700;800;900&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
.categories{
    display: flex;
    position: relative;
}
.side-bar{
    background-color: #f7f8fa;
    overflow-y: auto;
    overflow-x: hidden ;
    display: flex;
    flex-direction: column ;
    flex: 0 0 25%;
    height: 100vh;
}
.side-bar::-webkit-scrollbar{
    display: none;
}
.content{
    flex: 0 0 75%;
    overflow-y: auto;
    height: 100vh;
}
.content::-webkit-scrollbar{
    display: none;
}
.main-category{
    padding: 10px 10px;
    border-bottom: 1px solid #f1f1f1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.accordion-button{
    background-color: white ;
    box-shadow: none !important;
    font-weight: 400;
    padding: 0px 0px 10px !important;
    opacity: 0.800;
}
.accordion-button:not(.collapsed){
    opacity: 1;
    font-weight: 700;
}
.accordion-item{
    border: none !important;
    margin-bottom: 10px;
    text-align: center;
}
.accordion-button::after{
    width: 15px !important;
    height: 15px !important;
    background-size: contain !important;
}
img{
    width: 100%;
    height: auto;
    margin-bottom: 7px;
}
.third-child{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.third-child-name{
    font-size: 12px;
}
.accordion-body{
    padding: 0 !important;
}
.active-category{
    background-color: #fff;
    position: relative;
}
/*.active-category::after{
    position: absolute;
    top: 50%;
    left: 0;
    width: 3px;
    height: 100%;
    content: "";
    background: #ff761b;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}*/
.third-child {
    margin: 10px 0;
}
.accordion-item > div >.third-child {
    margin: 0px 0;
}
.main-category-name{
    font-size: 12px;
    font-weight: 400;
}
.active-category .main-category-name{
    font-size: 12px;
    font-weight: 700;
}
.third-child-name{
    font-size: 10px;
    font-weight: 400;
}
.accordion-button::after{
    transform: rotate(-90deg);
}
.categories[style="direction: rtl;"] .accordion-button::after{
    transform: rotate(90deg);
}
.accordion-button:not(.collapsed)::after{
    transform: unset !important;
}
.accordion-button:nth-child(1){
    margin-top: 10px;
}
.accordion-item:first-of-type{
    border-radius: 0 !important;
    margin-top: 10px !important;
}
.accordion-item{
    margin-bottom: 10px !important;
}
.categories[style="direction: rtl;"] .accordion-button::after{
    margin-right: auto;
    margin-left: unset !important;
}
.main-category-image img{
    width: 30px;
    height: 30px;
} 
a{
    text-decoration: none;
}
a:active{
    opacity: .5 !important;
}
</style>