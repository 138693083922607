<template>
<!--
    <div class="row" style="padding: 20px 5px;display: flex; flex-wrap: nowrap;overflow-x: scroll;">
        <div class="scroll_item"  v-for="(product, index) in data" :key="index"
             style="  min-width: 150px; padding: 0px 7px; overflow: hidden; box-sizing: border-box;">
            <a :href="product.productUrl" target="_blank" >
                <img alt="" border="0" hspace="0" align="center" vspace="0"
                     style="width: 100% !important; height: auto !important"
                      width="150" 
                     :src="product.productImageUrl" class="jsx-3694681155 mobile-full">
                     <div class="product-details">
                        <p class="title">{{ product.brandName }}</p>
                        <p class="desc">{{ product.productName }}</p>
                        <div class="price" v-if="product.productRegularPrice">
                            <div class="real-price" >
                            <del>{{ product.productRegularPrice }}</del>
                                <span>{{ product.productSalePrice }}</span>
                            </div>
                            <div class="discount">
                                <span>{{ product.productDiscount }}</span>
                            </div>
                        </div>
                        <div class="price" v-else>
                            <span>{{ product.productSalePrice }}</span>
                        </div>
                     </div>
            </a>
        </div>
    </div> -->
    <swiper
    :style="{
      '--swiper-navigation-color': '#fff',
      '--swiper-pagination-color': '#fff',
    }"
    :spaceBetween="10"
    :thumbs="{ swiper: thumbsSwiper }"
    :modules="modules"
    class="mySwiper2"
  >
    <swiper-slide
      v-for="(product, index) in data"
      :key="index"
      class="scroll_item"
      style="min-width: 150px; padding: 0px 7px; overflow: hidden; box-sizing: border-box;"
    >
      <a :href="product.productUrl" target="_blank">
        <img
          alt=""
          border="0"
          hspace="0"
          align="center"
          vspace="0"
          style="width: 100% !important; height: auto !important"
          width="150"
          :src="product.productImageUrl"
          class="jsx-3694681155 mobile-full"
        />
        <div class="product-details">
          <p class="title">{{ product.brandName }}</p>
          <p class="desc">{{ product.productName }}</p>
        </div>
      </a>
    </swiper-slide>
    </swiper>
    <swiper
    @swiper="setThumbsSwiper"
    :spaceBetween="10"
    :slidesPerView="4"
    :freeMode="true"
    :watchSlidesProgress="true"
    :modules="modules"
    class="mySwiper"
  >
    <swiper-slide
      v-for="(product, index) in data"
      :key="index"
      class="scroll_item"
      style="padding: 0px 7px; overflow: hidden; box-sizing: border-box;"
    >
        <img
          alt=""
          border="0"
          hspace="0"
          align="center"
          vspace="0"
          
          width="150"
          :src="product.productImageUrl"
          class="jsx-3694681155 mobile-full"
        />
    </swiper-slide>
    </swiper>
</template>

<script>
import { ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import sourceData from '../kimostore.json'
export default {
    components: {
    Swiper,
    SwiperSlide,
  },
    data() {
        return {
            data: sourceData,
            thumbsSwiper: null,
        }
    },
    methods: {
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper;
    },
  },
  setup() {
    return {
      modules: [FreeMode, Navigation, Thumbs],
    };
  },
}
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&display=swap');
        .scroll_item{
            padding: 7px !important;
            background-color: white;
            border-radius: 10px;
            margin-right: 10px ;
        }
        .scroll_item img{
            margin-bottom: 25px;
        }
        a{
            text-decoration: none;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            color: black;
            font-size: 12px;
        }
        .scroll_item .title{
            color: #c2c0c0;
            margin: 0;
            font-size: 12px;
        }
        .scroll_item .desc{
            color: black;
            margin: 0;
            font-size: 12px;
            margin-bottom: 15px;
        }
        .scroll_item .price{
            display: flex;
            justify-content: space-between;
            align-items: end;
        }
        .scroll_item .price .real-price{
            display: flex;
            align-items: center;
            flex-direction: column;
            font-size: 12px;
        }
        .scroll_item .price .real-price del{
            color: #777;
        }
        .scroll_item .price .real-price span{
            color: red;
            font-weight: 700;
        }
        .scroll_item .discount{
            background-color: #F8E4E3;
            padding: 0px 5px;
            border-radius: 7px;
            font-size: 9px;
            font-weight: 700;
        }
        .scroll_item .discount span{
            color: red;
        }
        *{
            font-family: 'Cairo', sans-serif;
            box-sizing: border-box;
        }
        .swiper-slide-visible{
            width: 70px !important;
            height: 70px !important;
            border: 1px solid #0000001a;
        }
        .swiper-slide-visible img{
            width: 100% !important;
            height: 100% !important;
        }
        .swiper-slide-thumb-active{
            border: 1px solid black;
        }
        
</style>