<template>
    <div v-if="data && styleData">
        <slide-categories v-if="styleData.sideMenuType == 'compact3'" :data="data" :styleData="styleData"></slide-categories>
        <categories v-else-if="styleData.sideMenuType == 'compact'" :data="data" :styleData="styleData"></categories>
    </div>
</template>

<script>
import axios from 'axios';
import SlideCategories from './SlideCategories.vue';
import categories from './categories.vue';
export default {
    components: {
        SlideCategories,
        categories
    },
    data(){
        return{
            data: null,
            styleData: null,
        }
    },
    async mounted(){
        const [categoryResult, styleResult] = await Promise.all([
            axios.get('https://app.splendapp.com/api/public/category/tree/89338421-3c8b-47a5-a4f6-59825129a998'),
            axios.get('https://app.splendapp.com/api/public/identity/a80523e3-86ca-4289-bd55-8e0578a4b861'),
        ]);

        if (categoryResult.data.success) {
            this.data = categoryResult.data.application.categories;
        }

        if (styleResult.data.success) {
            this.styleData = styleResult.data.layoutIdentity;
        }
    },
}
</script>