import { createRouter, createWebHistory } from "vue-router";
import Kimostore from "../components/KimoStore.vue";
import style1En from "../components/En/style1.vue";
import style1Ar from "../components/Ar/style1.vue";
import style2En from "../components/En/style2.vue";
import style2Ar from "../components/Ar/style2.vue";
import style3En from "../components/En/style3.vue";
import style3Ar from "../components/Ar/style3.vue";
import style4En from "../components/En/style4.vue";
import style4Ar from "../components/Ar/style4.vue";
import style5En from "../components/En/style5.vue";
import style5Ar from "../components/Ar/style5.vue";
import style6En from "../components/En/style6.vue";
import style6Ar from "../components/Ar/style6.vue";
import style7En from "../components/En/style7.vue";
import style7Ar from "../components/Ar/style7.vue";
import notificationStyle1 from "../components/notification/style1.vue"
import notificationStyle2 from "../components/notification/style2.vue"
import notificationStyle3 from "../components/notification/style3.vue"
import maincategories from "../components/mainCategories.vue"
import TabsCarousel from "../components/TabsCarousel.vue";

const routes = [
  {
    path: "/kimostore",
    component: Kimostore,
    name: "kimostore",
  },
  {
    path: "/notificatin-style1",
    component: notificationStyle1,
    name: "notificationStyle1",
  },
  {
    path: "/notificatin-style2",
    component: notificationStyle2,
    name: "notificationStyle2",
  },
  {
    path: "/notificatin-style3",
    component: notificationStyle3,
    name: "notificationStyle3",
  },
  {
    path: "/maincategories",
    component: maincategories,
    name: "maincategories",
  },
  {
    path: "/style1-en",
    component: style1En,
    name: "style1En",
  },
  {
    path: "/style1-ar",
    component: style1Ar,
    name: "style1Ar",
  },
  {
    path: "/style2-en",
    component: style2En,
    name: "style2En",
  },
  {
    path: "/style2-ar",
    component: style2Ar,
    name: "style2Ar",
  },
  {
    path: "/style3-en",
    component: style3En,
    name: "style3En",
  },
  {
    path: "/style3-ar",
    component: style3Ar,
    name: "style3Ar",
  },
  {
    path: "/style4-en",
    component: style4En,
    name: "style4En",
  },
  {
    path: "/style4-ar",
    component: style4Ar,
    name: "style4Ar",
  },
  {
    path: "/style5-en",
    component: style5En,
    name: "style5En",
  },
  {
    path: "/style5-ar",
    component: style5Ar,
    name: "style5Ar",
  },
  {
    path: "/style6-en",
    component: style6En,
    name: "style6En",
  },
  {
    path: "/style6-ar",
    component: style6Ar,
    name: "style6Ar",
  },
  {
    path: "/style7-en",
    component: style7En,
    name: "style7En",
  },
  {
    path: "/style7-ar",
    component: style7Ar,
    name: "style7Ar",
  },
  {
    path: "/tabs-carousel",
    component: TabsCarousel,
    name: "tabs-carousel",
  },
];

export default createRouter({
  history: createWebHistory(),
  routes,
});
