<template>
    <div class="row" style="padding: 20px 5px;display: flex; flex-wrap: nowrap;overflow-x: scroll;direction: rtl">
        <div class="scroll_item"  v-for="(product, index) in data" :key="index"
             style="  min-width: 250px; padding: 0px 7px; overflow: hidden; box-sizing: border-box;">
            <a :href="product.productUrl" target="_blank" style=" display: flex;">
                <div class="image">
                    <img alt="" border="0" hspace="0" align="center" vspace="0"
                        style="width: 100% !important; height: auto !important"
                        width="150" 
                        :src="product.productImageUrl" v-if="product.productImageUrl">
                </div>
                <div class="info">
                    <p class="desc" v-if="product.productName">{{ product.productName }}</p>
                     <div class="price">
                        <div class="real-price" v-if="product.productSalePrice">
                            <span>{{ product.productSalePrice }}</span>
                        </div>
                     </div>
                     <div class="" style="font-size: 12px;padding: 0 5px;">view more ></div>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import sourceData from '../../wayupsports.json'
export default {
    name: 'wayUpCarouselComponent',
    data() {
        return {
            data: sourceData,
        }
    },
}
</script>

<style scoped>
     @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&display=swap');
        .scroll_item{
            padding: 7px !important;
            margin-left: 10px ;
            background-color: white;
            border-radius: 10px;
        }
        a{
            text-decoration: none;
            color: #000;
        }
        .scroll_item .title{
            color: #c2c0c0;
            margin: 0;
        }
        .scroll_item .image{
            display: flex;
            flex: 0 0 50%;
            align-items: center;
        }
        .scroll_item .desc{
            color: black;
            margin: 0;
            font-size: 11px;
            padding: 0 5px;
            line-height: 1.5;
            margin-top: 5px;
        }
        .scroll_item .price{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 5px;
        }
        .scroll_item .price .real-price{
            display: flex;
            align-items: center;
            font-size: 11px;
        }
        .scroll_item .price .real-price del{
            color: #777;
        }
        .scroll_item .price .real-price span{
            color: #777;
        }
        .scroll_item .discount{
            display: flex;
            flex-direction: column ;
        }
        .scroll_item .discount span{
            color: white;
            padding: 3px;
            font-size: 9px;
            text-align: center;
            border-radius: 40px;
        }
        .scroll_item .discount span:first-of-type{
            background-color: orange;
            margin-bottom: 5px;
        }
        .scroll_item .discount span:nth-of-type(2){
            background-color: red;
        }
        *{
            font-family: 'Cairo', sans-serif;
        }
</style>