<template>
    <div class="row" style="padding: 20px 5px;display: flex; flex-wrap: wrap;direction: rtl">
        <div class="scroll_item"  v-for="(product, index) in data" :key="index"
             style="  min-width: 150px; padding: 0px 7px; overflow: hidden; box-sizing: border-box;">
            <a :href="product.productUrl" target="_blank" >
                <img alt="" border="0" hspace="0" align="center" vspace="0"
                     style="width: 100% !important; height: auto !important"
                      width="150" 
                     :src="product.productImageUrl" v-if="product.productImageUrl">
                     <div class="product-details">
                        <p class="title" v-if="product.brandName">{{ product.brandName }}</p>
                        <p class="desc" v-if="product.productName">{{ product.productName }}</p>
                        <div class="price" v-if="product.productRegularPrice">
                            <div class="real-price" >
                            <del>{{ product.productRegularPrice }}</del>
                                <span>{{ product.productSalePrice }}</span>
                            </div>
                            <div class="discount">
                                <span>{{ product.productDiscount }}</span>
                            </div>
                        </div>
                        <div class="price" v-else>
                            <span>{{ product.productSalePrice }}</span>
                        </div>
                     </div>
            </a>
        </div>
    </div>
</template>

<script>
import sourceData from '../../wayupsports.json'
export default {
    name: 'wayUpCarouselComponent',
    data() {
        return {
            data: sourceData,
        }
    },
}
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&display=swap');
        .scroll_item{
            padding: 7px !important;
            background-color: white;
            border-radius: 10px;
            width: 45%;
            margin: 10px;
        }
        .scroll_item img{
            margin-bottom: 25px;
        }
        a{
            text-decoration: none;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            color: black;
            font-size: 12px;
        }
        .scroll_item .title{
            color: #c2c0c0;
            margin: 0;
            font-size: 16px;
        }
        .scroll_item .desc{
            color: black;
            margin: 0;
            font-size: 16px;
            margin-bottom: 15px;
        }
        .scroll_item .price{
            display: flex;
            justify-content: space-between;
            align-items: end;
            font-size: 14px;
        }
        .scroll_item .price .real-price{
            display: flex;
            align-items: center;
            flex-direction: column;
            font-size: 14px;
        }
        .scroll_item .price .real-price del{
            color: #777;
        }
        .scroll_item .price .real-price span{
            color: red;
            font-weight: 700;
        }
        .scroll_item .discount{
            background-color: #F8E4E3;
            padding: 0px 10px;
            border-radius: 7px;
            font-size: 14px;
            font-weight: 700;
        }
        .scroll_item .discount span{
            color: red;
        }
        *{
            font-family: 'Cairo', sans-serif;
            box-sizing: border-box;
        }
</style>