<template>
    <div class="notification">
        <h1>Notifications</h1>
        <div class="notification-list" v-if="list">
            <div class="notification-item" v-for="item in list" :key="item.id" 
                :style="{
                    'background-image': `url(${item.imageUrl})`
                }"
                >
                <div class="notification-title">
                    {{ item.title }}
                </div>
                <div class="notification-body">
                    {{ item.body }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import data from "../../../notification.json"
export default {
    data (){
        return{
            list: null,
        }
    },
    mounted(){
        this.list = data.pagination.items.filter((item) => item.imageUrl)
        console.log(this.list)
    }
}
</script>

<style scoped>
.notification{
    padding: 20px;
}
.notification > h1{
    font-size: 16px;
    margin-bottom: 20px;
    color: #2d2626;
    font-weight: bold;
}
.notification-item{
    margin-bottom: 15px;
    border-radius:10px;
    background-size: cover;
    background-repeat: no-repeat;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.notification-item > div{
    margin-bottom: 10px;
    color: white;
    text-align: left;
    padding: 0 10px;
}
.notification-title{
    font-size: 15px;
}
.notification-body{
    font-size: 18px;
    font-weight: 500;
}
</style>